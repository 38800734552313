import React from 'react'
import { clsx } from 'clsx';
import { Formik } from 'formik';
import TextField from '../form/TextField';
import SelectField from '../form/SelectField';
import CheckboxField from '../form/CheckboxField';
import * as Yup from 'yup';
import * as styles from './MMOfficeOrderFormBlock.module.css';

const initialValues={
  moduleExistenceProtection: false,
  moduleHealth: false,
  moduleRetirement: false,
  modulePropertyLegalAccident: false,
  mmAnalyzer: false,
  company: '',
  gender: '',
  firstName: '',
  lastName: '',
  email: '',
  street: '',
  zipCode: '',
  city: '',
  phone: '',
  registerNo: '',
  comment: '',
  recommendation: '',
  accountOwner: '',
  iban: '',
  bic: '',
  bankName: '',
  newsletter: false,
  confirmedTermsAndConditions: false,
  promotionCode: '',
};

const validationSchema = Yup.object().shape({
  company: Yup.string()
    .required('Firma ist ungültig'),
  gender: Yup.string()
    .oneOf(['Herr', 'Frau'], 'Anrede ist nicht gültig')
    .required('Anrede ist nicht gültig'),
  firstName: Yup.string()
    .min(2, 'Vorname ist nicht gültig')
    .max(100, 'Vorname ist nicht gültig')
    .required('Vorname ist nicht gültig'),
  lastName: Yup.string()
    .min(2, 'Nachname ist nicht gültig')
    .max(100, 'Vorname ist nicht gültig')
    .required('Nachname ist nicht gültig'),
  email: Yup.string()
    .email('E-Mail ist nicht gültig')
    .required('E-Mail ist nicht gültig'),
  street: Yup.string()
    .min(2, 'Straße und Hausnummer ist nicht gültig')
    .max(100, 'Straße und Hausnummer ist nicht gültig')
    .required('Straße und Hausnummer ist nicht gültig'),
  zipCode: Yup.string()
    .matches(/^([0-9]{5})$/, 'PLZ ist nicht gültig')
    .required('PLZ ist nicht gültig'),
  city: Yup.string()
    .min(2, 'Ort ist nicht gültig')
    .max(100, 'Ort ist nicht gültig')
    .required('Ort ist nicht gültig'),
  phone: Yup.string()
    .min(2, 'Telefon ist nicht gültig')
    .max(100, 'Telefon ist nicht gültig')
    .required('Telefon ist nicht gültig'),
  registerNo: Yup.string()
    .min(2, 'Registernummer aus dem Vermittlungsregister ist nicht gültig')
    .max(100, 'Registernummer aus dem Vermittlungsregister ist nicht gültig')
    .required('Registernummer aus dem Vermittlungsregister ist nicht gültig'),
  accountOwner: Yup.string()
    .min(2, 'Kontoinhaber ist nicht gültig')
    .max(100, 'Kontoinhaber ist nicht gültig')
    .required('Kontoinhaber ist nicht gültig'),
  iban: Yup.string()
    .min(2, 'IBAN ist nicht gültig')
    .max(100, 'IBAN ist nicht gültig')
    .required('IBAN ist nicht gültig'),
  bic: Yup.string()
    .min(2, 'BIC ist nicht gültig')
    .max(100, 'BIC ist nicht gültig')
    .required('BIC ist nicht gültig'),
  bankName: Yup.string()
    .min(2, 'Kreditinstitut ist nicht gültig')
    .max(100, 'Kreditinstitut ist nicht gültig')
    .required('Kreditinstitut ist nicht gültig'),
  confirmedTermsAndConditions: Yup.mixed()
    .test(
      'confirmedTermsAndConditions',
      'Bitte bestätigen',
      (value) => value ===true
    )
})
.test({
  name: 'selectedModules',
  test: function(values) {
    return values.moduleExistenceProtection === true
      || values.moduleHealth === true
      || values.modulePropertyLegalAccident === true
      || values.moduleRetirement === true
      || this.createError({
        path: 'selectedModules',
        message: 'Bitte Deine Module auswählen'
      });
    }
});

const countModules = (values) => {
  return values.moduleExistenceProtection
    + values.moduleHealth
    + values.moduleRetirement
    + values.modulePropertyLegalAccident;
}

const getPrice = (values) => {
  let moduleCount = countModules(values);
  let newPrice = values.mmAnalyzer ? 30 : 0;
  switch (moduleCount) {
    case 0: return 0;
    case 1: return newPrice +  69;
    case 2: return newPrice +  99;
    case 3: return newPrice + 119;
    case 4: return newPrice + 129;
    default: return undefined
  }
}

const updateForm = (values) => {
  if (countModules(values)  === 0) {
    // Analyzer can not be ordered without a module. Uncheck analyzer checkbox, if no module was selected.
    values.mmAnalyzer = false;
  }
}

const MMOfficeOrderFormBlock = () => {
  const [orderStatus, setOrderStatus] = React.useState('new');

  const onSubmit = async (values) => {
    try {
      const response = await fetch('/api/mm-office-order-form', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(values),
      });
      if (response.ok) {
        setOrderStatus('completed');
      } else {
        const errmsg = await response.json();
        console.error('Invalid api response', response.status, errmsg);
        setOrderStatus('failed');
      }
    } catch (error) {
      console.error('Api request faild', error);
      setOrderStatus('failed');
    }
  }

  if (orderStatus === 'completed') {
    return (
      <section className="embed-block">
        <div className="py-block block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full px-grid">
              <div className={clsx('mx-auto p-3', styles.formContainer)}>
                <main className={styles.main}>
                  <h1 className={styles.h1}>M&M Office</h1>
                  <p className={styles.p}>Vielen Dank für Deine Bestellung</p>
                </main>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          submitCount,
        }) => {
          /*console.log('values', values);
          console.log('touched', touched);
          console.log('errors', errors);
          console.log('isSubmitting', isSubmitting);
          console.log('submitCount', submitCount);*/
          return (
            <section className="embed-block">
              <div className="py-block block-w-container">
                <div className="flex flex-row flex-wrap -mx-grid">
                  <div className="w-full px-grid">
                    <div className={clsx('mx-auto p-3', styles.formContainer)}>
                      <main className={styles.main}>
                        <form onSubmit={handleSubmit} id="new_submission" noValidate="novalidate" acceptCharset="UTF-8" method="post">
                          {orderStatus === 'failed' && (
                            <div className={styles.alertDanger}>Die Anfrage konnte nicht gesendet werden. Bitte versuche es später erneut.</div>
                          )}
                          {submitCount > 0 && errors && Object.keys(errors).length > 0 && (
                            <div className={styles.alertDanger}>Bitte behebe die folgenden Probleme:</div>
                          )}
                          <h1 className={styles.h1}>M&M Office</h1>
                          <h2 className={styles.h2}>Bestellvorgang</h2>
                          <p className={styles.p}>Gültig für Gewerbetreibende und Unternehmen. M&M Office ist eine Onlineanwendung. Das Nutzungsrecht ist personengebunden und nicht übertragbar.</p>
                          <h3 className={styles.h3}>Wähle Deine Module</h3>
                          {submitCount > 0 && errors.selectedModules && (<div className={styles.errorMessage}>{errors.selectedModules}</div>)}
                          <div className="grid grid-cols-1 sm:grid-cols-2 mb-4">
                            <div className="mb-6">
                              <div>
                                <div className={styles.modulPriceBlock}>
                                  <div className={styles.modulPriceBlockInner}>
                                    <span className={styles.modulPrice}>{getPrice(values)} EUR</span><br/>
                                    <span>pro Monat</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="pl-3">
                              <CheckboxField
                                label="Existenzsicherung"
                                name="moduleExistenceProtection"
                                values={values}
                                touched={touched}
                                submitCount={submitCount}
                                errors={{moduleExistenceProtection: errors.selectedModules}}
                                onChange={(ev) => {
                                  values.moduleExistenceProtection = !values.moduleExistenceProtection;
                                  updateForm(values);
                                  handleChange(ev);
                                }}
                              />
                              <CheckboxField
                                label="Gesundheit"
                                name="moduleHealth"
                                values={values}
                                touched={touched}
                                submitCount={submitCount}
                                errors={{moduleHealth: errors.selectedModules}}
                                onChange={(ev) => {
                                  values.moduleHealth = !values.moduleHealth;
                                  updateForm(values);
                                  handleChange(ev);
                                }}
                              />
                              <CheckboxField
                                label="Altersvorsorge"
                                name="moduleRetirement"
                                values={values}
                                touched={touched}
                                submitCount={submitCount}
                                errors={{moduleRetirement: errors.selectedModules}}
                                onChange={(ev) => {
                                  values.moduleRetirement = !values.moduleRetirement;
                                  updateForm(values);
                                  handleChange(ev);
                                }}
                              />
                              <CheckboxField
                                label="Eigentum, Recht &amp; Unfall"
                                name="modulePropertyLegalAccident"
                                values={values}
                                touched={touched}
                                submitCount={submitCount}
                                errors={{modulePropertyLegalAccident: errors.selectedModules}}
                                onChange={(ev) => {
                                  values.modulePropertyLegalAccident = !values.modulePropertyLegalAccident;
                                  updateForm(values);
                                  handleChange(ev);
                                }}
                              />
                            </div>
                          </div>

                          <p className={styles.p}>Die monatlichen Preise pro Modulkombination staffeln sich wie folgt:</p>
                          <ul className={styles.ul}>
                            <li className={styles.li}>1 Modul: 69 EUR</li>
                            <li className={styles.li}>2 Module: 99 EUR</li>
                            <li className={styles.li}>3 Module: 119 EUR</li>
                            <li className={styles.li}>4 Module: 129 EUR</li>
                          </ul>

                          <h4 className={styles.h4}>Exklusives M&M Tarifoptimierer Angebot sichern</h4>
                          <p className={styles.p}> Sichere Dir jetzt den M&M Tarifoptimierer zum exklusiven Vorteilspreis und spare künftig wertvolle Zeit in Deinen Beratungen. Mit dem M&M Tarifoptimierer behältst Du alle Tarifleistungen von Bestands- und Markttarifen stets im Blick, kannst die jeweils relevanten Leistungskriterien individuell auswählen und erhältst am Ende die prozentuale Passgenauigkeit der gewünschten Leistungen im Vergleich zu den tatsächlichen Tarifleistungen grafisch aufbereitet. </p>

                          <br/>
                          <CheckboxField
                            label="Jetzt M&amp;M Tarifoptimierer für nur 30 Euro monatlich bestellen (Normalpreis 59 EUR pro Monat)*"
                            name="mmAnalyzer"
                            values={values}
                            disabled={(countModules(values) === 0)}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            onChange={(ev) => {
                              values.mmAnalyzer = !values.mmAnalyzer;
                              updateForm(values);
                              handleChange(ev);
                            }}
                          />

                          <p className={styles.p}><small className={styles.small}>* Gültig nur in Kombination mit mindestens einem M&M Office Modul. Wähle mindestens ein M&M Office Modul aus.</small></p>
                          <br/><br/>

                          <p className={styles.p}>Hinzu kommt eine einmalige Aktivierungsgebühr für die Online-Freischaltung Deines Zugangs in Höhe von 99 EUR. Alle angegebenen Preise verstehen sich netto zzgl. gesetzlicher Mehrwertsteuer. </p>

                          <p className={styles.p}>Die Vertragslaufzeit beträgt 12 Monate. der Vertrag verlängert sich automatisch um 12 weitere Monate, sofern keine Partei unter Einhaltung von 2 Monaten zum Ablauf der jeweiligen Vertragslaufzeit ordentlich kündigt. </p>

                          <h3 className={styles.h3}>Systemvoraussetzungen</h3>
                          <p className={styles.p}>Du benötigst einen internetfähigen PC, Laptop oder ein internetfähiges Endgerät wie Smartphone oder Tablet, eine Breitbandverbindung (z.B. DSL oder eine mobile Datenverbindung mit empfohlenen mindestens 6000 kbit/s) und einen Adobe Acrobat Reader DC.</p>

                          <h3 className={styles.h3}>Deine Daten</h3>

                          <TextField
                            label="Firma"
                            name="company"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <SelectField
                            label="Anrede"
                            name="gender"
                            options={{
                              'Herr': 'Herr',
                              'Frau': 'Frau',
                            }}
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            label="Vorname"
                            name="firstName"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            label="Nachname"
                            name="lastName"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            type="email"
                            label="E-Mail"
                            name="email"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            label="Straße und Hausnummer"
                            name="street"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <div className="grid grid-cols-4 gap-4">
                            <TextField
                              label="PLZ"
                              name="zipCode"
                              values={values}
                              errors={errors}
                              touched={touched}
                              submitCount={submitCount}
                              required={true}
                              className="col-span-1"
                            />

                            <TextField
                              label="Ort"
                              name="city"
                              values={values}
                              touched={touched}
                              submitCount={submitCount}
                              errors={errors}
                              required={true}
                              className="col-span-3"
                            />
                          </div>

                          <TextField
                            label="Telefon"
                            name="phone"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            label="Registernummer aus dem Vermittlungsregister"
                            name="registerNo"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            label="Anmerkungen zu Deiner Bestellung"
                            name="comment"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={false}
                          />

                          <SelectField
                            label="Wie bist Du auf uns aufmerksam geworden"
                            name="recommendation"
                            options={{
                              'Empfehlung': 'Empfehlung',
                              'Social Media': 'Social Media',
                              'Presse': 'Presse',
                              'Websuche': 'Websuche'
                            }}
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={false}
                          />

                          <h3 className={styles.h3}>Zahlungsinformationen</h3>
                          <h5 className={styles.h5}>SEPA-Basislastschrift</h5>
                          <p className={styles.p}>Gläubiger-Identifikationsnummer: DE52ZZZ00000086236 (die Information bzgl. Deiner Mandatsreferenz-Nr. geht Dir zusammen mit Deiner Kunden-Nr. und der Freischaltungsmitteilung zu). Hiermit ermächtige ich die Firma MORGEN & MORGEN GmbH, alle aus diesem Vertrag fälligen Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der Firma MORGEN & MORGEN GmbH auf mein Konto gezogenen Lastschriften einzulösen.</p>
                          <p className={styles.p}><strong>Hinweis: Du kannst innerhalb von acht Wochen beginnend, mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit Deinem Kreditinstitut vereinbarten Bedingungen.</strong></p>
                          <p className={styles.p}>Zahlweise: Wiederkehrend (monatlich am 1. Bankarbeitstags eines Monats)</p>

                          <TextField
                            label="Kontoinhaber"
                            name="accountOwner"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            label="IBAN"
                            name="iban"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            label="BIC"
                            name="bic"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <TextField
                            label="Kreditinstitut"
                            name="bankName"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={true}
                          />

                          <h3 className={styles.h3}>Aktualität</h3>
                          <h5 className={styles.h5}>Aktualität des Berechnungsverfahrens in M&M Office</h5>
                          <p className={styles.p}>MORGEN & MORGEN ist um eine höchstmögliche Tarifaktualität der Online-Anwendung bemüht. Eine taggenaue Tarifaktualität kann jedoch nicht zugesagt und vereinbart werden. Mit jedem Neustart steht Dir automatisch die aktuelle Version von M&M Office zur Verfügung.</p>
                          <p className={styles.p}>Der kostenlose M&M Office Newsletter enthält wichtige Hinweise zu Programmfunktionen und -aktualisierungen, Updateinstallationen und Produkterneuerungen. Wir werden Deine personenbezogenen Daten, die wir für den Versand des Newsletters verarbeiten, Dritten nicht zur Verfügung stellen. Du kannst den Erhalt des Newsletter jederzeit bequem mit einem Mausklick (im Newsletter unter der Funktion „abbestellen“) oder per E-Mail an info@morgenundmorgen.de widerrufen.</p>

                          <CheckboxField
                            label="Ja, ich möchte den kostenlosen M&amp;M Office Newsletter erhalten, habe den Hinweis gelesen und akzeptiert."
                            name="newsletter"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            onChange={handleChange}
                          />

                          <h3 className={styles.h3}>M&M Office Vertragsverhältnis</h3>
                          <p className={styles.p}>Im Übrigen gelten die <a className={styles.a} href="https://a.storyblok.com/f/119468/x/1601709fbd/morgen-morgen_gmbh_agb.pdf" target="_blank">Allgemeinen Geschäftsbedingungen der MORGEN & MORGEN GmbH</a> in ihrer, zum Zeitpunkt Deiner Bestellung gültigen Fassung sowie die Vereinbarung (Anlage 2) zur <a className={styles.a} href="https://a.storyblok.com/f/119468/x/193db6d45a/morgen-morgen_avv.pdf" target="_blank">Auftragsverarbeiter-Vereinbarung“ gemäß Art. 28 DSGVO</a>.</p>

                          <CheckboxField
                            label="Hiermit bestätige ich, vorliegenden Vertrag ausschließlich in bzw. zur Ausübung meiner gewerblichen/selbstständigen Tätigkeit zu schließen und nicht als Verbraucher."
                            name="confirmedTermsAndConditions"
                            required={true}
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            onChange={handleChange}
                          />

                          <h3 className={styles.h3}>Aktionscode</h3>
                          <p className={styles.p}>Wir vergeben regelmäßig Aktionscodes. Diese findest Du über unsere Social Media Kanäle oder den M&M Office Newsletter. Hast Du einen aktuellen Aktionscode? Dann trage ihn hier ein.</p>

                          <TextField
                            label="Aktion"
                            name="promotionCode"
                            values={values}
                            touched={touched}
                            submitCount={submitCount}
                            errors={errors}
                            required={false}
                          />

                          <p className={styles.p}>Indem Du unten auf „Jetzt bestellen“ klickst, stimmst Du zu, dass MORGEN & MORGEN die oben angegebenen persönlichen Daten speichert und verarbeitet, um Dir die angeforderten Inhalte bereitzustellen. Solltest Du Dich gegen den M&M Newsletter entschieden haben, werden wir Dich lediglich zur Beantwortung Deiner Anfrage kontaktieren.</p>

                          <br/><br/>
                          <input
                            type="submit"
                            name="commit"
                            value="Jetzt bestellen"
                            className={clsx(styles.btn, styles.btnPrimary)}
                            onClick={() => {
                              window.location.hash = '';
                              window.location.hash = 'top';
                            }}
                          />
                        </form>
                      </main>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        }}
      </Formik>
    </div>
  )
}

export default MMOfficeOrderFormBlock;
