import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import ButtonGroup from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/ButtonGroup"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import useFadeInBottomStaggered from "../../@yumgmbh/gatsby-theme-yum-components/components/effects/useFadeInBottomStaggered"

const BenefitsBlock = ({ block }) => {
  const { refFadeInBottomStaggeredCollection } = useFadeInBottomStaggered()

  const itemGrids = [
    "md:w-1/2",
    "md:w-1/2",
    "md:w-1/2",
    "md:w-1/2 lg:w-1/3",
    "md:w-1/2 lg:w-1/3 xl:w-1/4",
    "md:w-1/2 lg:w-1/3 xl:w-1/5",
  ]

  return (
    <BlockWrapper block={block} showHeadline="true">
      {block.benefit_items?.length > 0 && (
        <div
          className={`flex flex-row flex-wrap -mx-grid -mb-4 ${
            block.headline?.length > 0 || block.subheadline?.length > 0
              ? "mt-8"
              : ""
          }`}
        >
          {block.benefit_items?.length > 0 &&
            block.benefit_items.map((item, index) => (
              <div
                ref={refFadeInBottomStaggeredCollection}
                className={`w-full ${
                  block.benefit_items?.length < 5
                    ? itemGrids[block.benefit_items?.length]
                    : itemGrids[5]
                } px-grid mb-8`}
              >
                <div className={`text-white ${item.bg_color} h-full`}>
                  <div className="p-4 text-center">
                    {item.icon?.filename?.length > 0 && (
                      <Image
                        className="w-10 mx-auto mb-2 md:w-12"
                        image={item.icon}
                        aspectRatio="1by1"
                      />
                    )}
                    <span className="block mt-2 hyphens-auto">
                      {item.title}
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </BlockWrapper>
  )
}

export default BenefitsBlock
