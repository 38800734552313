import React, { useState, useRef, useEffect } from "react"
import Blocks from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/_Blocks"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons"

import { ReactComponent as IconAltersvorsorge } from "../../../../../images/icon-altersvorsorge.svg"
import { ReactComponent as IconBerufsunfaehigkeit } from "../../../../../images/icon-berufsunfaehigkeit.svg"
import { ReactComponent as IconEigentumrecht } from "../../../../../images/icon-eigentumrecht.svg"
import { ReactComponent as IconExistenz } from "../../../../../images/icon-existenz.svg"
import { ReactComponent as IconGesundheit } from "../../../../../images/icon-gesundheit.svg"
import { ReactComponent as IconRisikoleben } from "../../../../../images/icon-risikoleben.svg"

const AccordionFolderBlock = ({ block }) => {
  const [activeAccordionState, setActiveAccordionState] = useState(null)
  const refContainers = useRef([])

  const refContainersCollection = (el) => {
    if (el && !refContainers.current.includes(el)) {
      refContainers.current.push(el)
    }
  }

  // Workaround: Verhindert falsche Start-Höhe des Contents, weil Bild zu lange zum Laden braucht. => Refactoring
  useEffect(() => {
    setTimeout(() => {
      setActiveAccordionState(null)
    }, 1000)
  }, [])

  const toggleAccordion = (index) => {
    const newActiveAccordionState =
      activeAccordionState !== index ? index : null
    setActiveAccordionState(newActiveAccordionState)
  }

  return (
    <BlockWrapper block={block} showHeadline="true">
      {block.folder_items?.length > 0 &&
        block.folder_items.map((item, index) => (
          <div
            key={index}
            className={
              block.headline?.length > 0 || block.subheadline?.length > 0
                ? "mt-8"
                : ""
            }
          >
            <button
              className={`w-full group text-left py-4 border-primary  border-t font-bold px-grid ${
                activeAccordionState === index ? "" : ""
              } ${index > 0 ? "-mt-0.5" : ""} `}
              onClick={() => toggleAccordion(index)}
            >
              <div className="flex flex-row items-center">
                <span className="mr-4 text-sm text-secondary">
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    size="2x"
                    className={`mr-2 transition-transform ease-out duration-500 transform ${
                      activeAccordionState === index ? " -rotate-180" : ""
                    }`}
                  />
                </span>
                <span className="flex items-center w-12 h-12 mr-4 ">
                  {item.areaicon === "altersvorsorge" ? (
                    <IconAltersvorsorge className="block w-12 h-12 text-primary group-hover:text-secondary" />
                  ) : item.areaicon === "berufsunfaehigkeit" ? (
                    <IconBerufsunfaehigkeit className="block w-12 h-12 text-primary group-hover:text-secondary" />
                  ) : item.areaicon === "eigentumrecht" ? (
                    <IconEigentumrecht className="block w-12 h-12 text-primary group-hover:text-secondary" />
                  ) : item.areaicon === "existenz" ? (
                    <IconExistenz className="block w-12 h-12 text-primary group-hover:text-secondary" />
                  ) : item.areaicon === "gesundheit" ? (
                    <IconGesundheit className="block w-12 h-12 text-primary group-hover:text-secondary" />
                  ) : item.areaicon === "risikoleben" ? (
                    <IconRisikoleben className="block w-12 h-12 text-primary group-hover:text-secondary" />
                  ) : (
                    ""
                  )}
                </span>
                <span className="leading-8 group-hover:text-secondary">
                  {item.title}
                </span>
              </div>
            </button>
            <div
              className={`overflow-hidden transition-all ease-out duration-500 ${
                index + 1 === block.folder_items?.length
                  ? " border-primary border-b"
                  : ""
              }`}
              ref={refContainersCollection}
              style={{
                height: `${
                  activeAccordionState === index
                    ? `${refContainers?.current[index]?.scrollHeight}px`
                    : "0px"
                }`,
              }}
            >
              <div className="-mx-grid">
                {item.content?.length > 0 &&
                  item.content.map((content) =>
                    React.createElement(Blocks(content.component), {
                      key: content._uid,
                      block: content,
                      type: content.component,
                    })
                  )}
              </div>
            </div>
          </div>
        ))}
    </BlockWrapper>
  )
}

export default AccordionFolderBlock
