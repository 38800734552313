import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const LogoScrollerBlock = ({ block }) => {
  const sizes = {
    small: "logo-slim",
    normal: "logo-normal",
    large: "logo-wide",
  }

  return (
    <BlockWrapper
      block={block}
      blockWidth="container"
      showHeadline="true"
      className=""
    >
      <div
        style={{
          "--logo-scroller-duration":
            block.logo_items?.length > 0
              ? block.logo_items?.length * 1.75 + "s"
              : "1s",
        }}
      >
        <div className="relative flex flex-row overflow-x-hidden flex-nowrap">
          <span className="absolute z-50 block w-16 h-full bg-gradient-to-r from-white " />
          <span className="absolute right-0 z-50 block w-16 h-full bg-gradient-to-l from-white " />
          <div className="flex flex-row flex-nowrap logo-scroller-animation">
            {block.logo_items?.length > 0 &&
              block.logo_items.map((item, index) => (
                <div className="" key={index}>
                  <div className="flex items-center w-full h-full px-6 py-8 md:px-4">
                    <Link
                      className={`block w-full h-auto text-secondary max-w-[200px] transition-transform duration-300 transform ${
                        item?.link?.cached_url?.length > 0
                          ? "hover:scale-110"
                          : ""
                      }`}
                      link={item.link}
                    >
                      <Image
                        image={item.image}
                        className={`block h-auto ${sizes[item.logo_size]}`}
                      />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-row flex-nowrap logo-scroller-animation">
            {block.logo_items?.length > 0 &&
              block.logo_items.map((item, index) => (
                <div className="" key={index}>
                  <div className="flex items-center w-full h-full py-8 px-grid">
                    <Link
                      className={`block w-full h-auto text-secondary max-w-[200px] transition-transform duration-300 transform ${
                        item?.link?.cached_url?.length > 0
                          ? "hover:scale-110"
                          : ""
                      }`}
                      link={item.link}
                    >
                      <Image
                        image={item.image}
                        alt={item.image.alt}
                        title={item.image.title}
                        className={`block h-auto ${sizes[item.logo_size]}`}
                      />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-row flex-nowrap logo-scroller-animation">
            {block.logo_items?.length > 0 &&
              block.logo_items.map((item, index) => (
                <div className="" key={index}>
                  <div className="flex items-center w-full h-full py-8 px-grid">
                    <Link
                      className={`block w-full h-auto text-secondary max-w-[200px] transition-transform duration-300 transform ${
                        item?.link?.cached_url?.length > 0
                          ? "hover:scale-110"
                          : ""
                      }`}
                      link={item.link}
                    >
                      <Image
                        image={item.image}
                        alt={item.image.alt}
                        title={item.image.title}
                        className={`block h-auto ${sizes[item.logo_size]}`}
                      />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default LogoScrollerBlock
