import React, { useRef } from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import useParallax from "../../../../hooks/useParallax"

const TextBlock = ({ block }) => {
  const scrollRef = useRef(null)
  //useParallax(scrollRef, 0.08)

  return (
    <BlockWrapper
      block={block}
      blockWidth={block.flow && block.flow === "3" ? "container" : "content"}
      showHeadline="true"
      showButtons="true"
    >
      <div
        ref={scrollRef}
        className={
          block.headline?.length > 0 || block.subheadline?.length > 0
            ? "mt-8"
            : ""
        }
      >
        <TextReveal>
          <Richtext
            flow={block.flow}
            text={block.text}
            className={`${
              block.text_size === "text-lg" ? block.text_size : ""
            } ${block.invert_color ? "prose-invert" : ""}`}
          />
        </TextReveal>
      </div>
    </BlockWrapper>
  )
}

export default TextBlock
