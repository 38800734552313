// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   quote -> textarea / Zitat
//   author -> text / Autor
//   author_description -> text / Beschreibung des Autors
//   image -> asset / Bild
// end of automatic

import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"
import HeadlineReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/HeadlineReveal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/pro-solid-svg-icons"
import Figure from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/Figure"

import Dot from "../../../../images/dot.svg"

const QuoteBlock = ({ block }) => {
  let hasImage = block.image?.filename?.length > 0
  return (
    <>
      {block.quote && block.quote.length > 0 && (
        <BlockWrapper block={block} blockWidth="content">
          <div className="flex flex-row flex-wrap justify-center -mx-grid">
            <div className="w-full text-center">
              <span
                className="inline-block w-12 h-2 mb-12"
                style={{
                  backgroundImage: "url(" + Dot + ")",
                }}
              />
            </div>
            {hasImage && (
              <div className="w-full md:w-1/3 px-grid">
                <Figure image={block.image} aspectRatio="1by1" />
              </div>
            )}
            <div className="block w-full md:w-2/3 px-grid">
              <blockquote
                className={`mt-4 md:mt-0 ${hasImage ? "" : "text-center"}`}
              >
                <HeadlineReveal>
                  <p
                    className={`relative block text-2xl leading-normal md:text-4xl md:leading-normal uppercase text-darkblue ${
                      (block.link?.cached_url?.length > 0 ||
                        block.link?.url?.length > 0) &&
                      "group-hover:text-secondary"
                    }`}
                  >
                    {block.quote}
                  </p>
                </HeadlineReveal>
                {block.author?.length > 0 && (
                  <footer className="mt-8">
                    <TextReveal>
                      <FontAwesomeIcon
                        icon={faLongArrowAltRight}
                        size="1x"
                        className="mt-1.5 mr-4"
                      />
                      <span className="text-lg ">{block.author} </span>
                      {block.author_description?.length > 0 && (
                        <span className="text-lg ">
                          {" "}
                          | {block.author_description}
                        </span>
                      )}
                    </TextReveal>
                  </footer>
                )}
              </blockquote>
            </div>
            <div className="w-full text-center">
              <span
                className="inline-block w-12 h-2 mt-12"
                style={{
                  backgroundImage: "url(" + Dot + ")",
                }}
              />
            </div>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default QuoteBlock
