import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faPlus } from "@fortawesome/pro-light-svg-icons"
import Star from "../../../images/stern.svg"

const RatingsAccordeon = ({ ratings }) => {
  const [splittedRatings, setSplittedRatings] = useState(null)

  const [activeAccordionState, setActiveAccordionState] = useState(null)
  const refContainers = useRef([])

  const refContainersCollection = (el) => {
    if (el && !refContainers.current.includes(el)) {
      refContainers.current.push(el)
    }
  }

  // Group ratings by rating value
  const sortRatings = (arr) => {
    let res = []
    res[5] = { id: "5", ratingTitle: "5", items: [] }
    res[4] = { id: "4", ratingTitle: "4", items: [] }
    res[3] = { id: "3", ratingTitle: "3", items: [] }
    res[2] = { id: "2", ratingTitle: "2", items: [] }
    res[1] = { id: "1", ratingTitle: "1", items: [] }
    arr.forEach(({ rating, ...item }) => {
      console.log(rating)
      res[rating]?.items?.push({ rating, ...item })
    })
    return Object.values(res)
      .sort((a, b) => {
        return b.id - a.id
      })
      .filter((obj) => {
        if (obj.ratingTitle !== null) {
          return obj
        }
        return null
      })
  }
  useEffect(() => {
    ratings && ratings.length > 0 && setSplittedRatings(sortRatings(ratings))

    return () => {
      setSplittedRatings(null)
    }
  }, [ratings])

  // Workaround: Verhindert falsche Start-Höhe des Contents, weil Bild zu lange zum Laden braucht. => Refactoring
  useEffect(() => {
    setTimeout(() => {
      setActiveAccordionState(null)
    }, 1000)
  }, [])

  const toggleAccordion = (index) => {
    const newActiveAccordionState =
      activeAccordionState !== index ? index : null
    setActiveAccordionState(newActiveAccordionState)
  }
  return (
    <>
      {splittedRatings?.length > 0 ? (
        splittedRatings.map((item, index) => {
          const starsArr =
            !isNaN(Math.floor(item.ratingTitle)) &&
            Array.from(new Array(Math.floor(item.ratingTitle)))
          return (
            <div key={index}>
              <button
                className={`w-full group text-left py-4 border-primary  border-t font-bold px-grid ${
                  activeAccordionState === index ? "" : ""
                } ${index > 0 ? "-mt-0.5" : ""} ${
                  item.items?.length === 0 ? "pointer-events-none" : ""
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <div className="flex flex-row items-center">
                  <span
                    className={`mr-4 text-sm text-secondary ${
                      item.items?.length === 0 ? "invisible" : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      size="2x"
                      className={`mr-2 transition-transform ease-out duration-500 transform ${
                        activeAccordionState === index ? " -rotate-180" : ""
                      }`}
                    />
                  </span>
                  <span className="leading-8 group-hover:text-secondary">
                    <span
                      key={index}
                      className="inline-block w-24 mr-4 group-hover:text-secondary"
                    >
                      <div className="flex">
                        {starsArr.length > 0 ? (
                          starsArr?.map((star, index) => (
                            <img
                              key={index}
                              src={Star}
                              alt=""
                              width="20"
                              height="20"
                            />
                          ))
                        ) : (
                          <div>{item.ratingTitle}</div>
                        )}
                      </div>
                    </span>
                  </span>
                  <span className="ml-4 leading-8">({item.items.length})</span>
                </div>
              </button>
              <div
                className={`overflow-auto transition-all ease-out duration-500 ${
                  index + 1 === splittedRatings?.length
                    ? " border-primary border-b"
                    : ""
                }`}
                ref={refContainersCollection}
                style={{
                  maxHeight: "80vh",
                  height: `${
                    activeAccordionState === index
                      ? refContainers?.current[index]?.scrollHeight + 8 + "px"
                      : "0px"
                  }`,
                }}
              >
                <div className="">
                  <table className="w-full border-collapse table-auto">
                    <tbody>
                      {item.items?.length > 0 &&
                        item.items.map((content, index) => {
                          return (
                            <tr
                              className="bg-gray-100 even:bg-gray-50"
                              key={index}
                            >
                              <td className="px-4 py-2">{content.company}</td>
                              <td className="px-4 py-2">{content.tarifname}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <p className="px-4 py-2 text-lg font-bold border-t border-b border-red text-red">
          {typeof window !== "undefined" && window.storyblok?.inEditor
            ? "In der Storyblok-Vorschau sind die Rating-Daten nicht verfügbar und können deshalb nicht dargestellt werden."
            : "Leider sind aktuelle keine Daten zu diesem Rating verfügbar."}
        </p>
      )}
    </>
  )
}

export default RatingsAccordeon
