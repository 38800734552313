// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   headline -> text / Überschrift
//   headline_level -> option
//   logo_items -> bloks
// end of automatic

import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import ButtonGroup from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/ButtonGroup"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"

import useFadeInBottomStaggered from "../effects/useFadeInBottomStaggered"

const LogoGridBlock = ({ block }) => {
  const { refFadeInBottomStaggeredCollection } = useFadeInBottomStaggered()

  const sizes = {
    small: "logo-sm",
    normal: "logo-md",
    large: "logo-lg",
  }
  return (
    <BlockWrapper block={block} showHeadline="true">
      <div className="flex flex-row flex-wrap pt-8 -mx-grid">
        {block.logo_items?.length > 0 &&
          block.logo_items.map((item, index) => (
            <div
              className="relative w-1/2 mb-8 md:w-1/3 lg:w-1/4 px-grid"
              key={index}
            >
              <div className="flex items-center justify-center w-full h-full text-center">
                <div
                  ref={refFadeInBottomStaggeredCollection}
                  className="flex items-center w-full h-full py-4 px-grid"
                >
                  <Link className="block w-full h-auto" link={item.link}>
                    <Image
                      image={item.image}
                      className={`block w-full h-auto ${sizes[item.logo_size]}`}
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        <div className="relative w-full mt-8 px-grid">
          <div className="lg:px-16">
            <TextReveal>
              <Richtext text={block.text} />
            </TextReveal>
            {block.buttons?.length > 0 && (
              <ButtonGroup className="mt-4" buttons={block.buttons} />
            )}
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default LogoGridBlock
