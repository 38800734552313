import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import ButtonGroup from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/ButtonGroup"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"

const CallToActionBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth={block.flow && block.flow === "3" ? "container" : "content"}
      showHeadline="true"
    >
      <div
        className={
          block.headline?.length > 0 || block.subheadline?.length > 0
            ? "mt-8"
            : ""
        }
      >
        <TextReveal>
          <Richtext
            flow={block.flow}
            text={block.text}
            className={` ${
              block.text_size === "text-lg" ? block.text_size : ""
            }`}
          />
        </TextReveal>
        {block.buttons?.length > 0 && (
          <ButtonGroup className="mt-4" buttons={block.buttons} />
        )}
      </div>
    </BlockWrapper>
  )
}

export default CallToActionBlock
