// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   headline_level -> option / Überschriftengröße
//   subheadline -> text / Unterzeile
//   text -> markdown / Text
//   video_url -> text / Video-URL
//   caption -> textarea / Bildbeschreibung (optional)
// end of automatic

import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import VideoFigure from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/VideoFigure"

import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"
import ImageReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/ImageReveal"

const TextVideoBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} blockWidth="container" showHeadline={true}>
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="w-full pt-8 lg:w-1/2 px-grid">
          <ImageReveal>
            <VideoFigure
              video_url={block.video_url}
              ratio={block.ratio}
              _uid={block._uid}
              caption={block.caption}
            />
          </ImageReveal>
        </div>
        <div
          className={`w-full mb-4 lg:w-1/2 px-grid ${
            block.text_position === "left" ? "lg:order-first" : ""
          }`}
        >
          <TextReveal>
            <Richtext text={block.text} className="pt-8" />
          </TextReveal>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default TextVideoBlock
