import React from "react"
import { useCookiebotConsent, CookiebotServiceId } from '../../../../hooks/useCookiebotConsent';
import CookiebotPlaceholder from "../../../../components/modules/CookiebotPlaceholder"

const Video = ({ video_url, ratio, _uid, className }) => {
  let video
  let video_code
  let video_plattform

  if (video_url.includes("vimeo.com")) {
    video_plattform = "https://player.vimeo.com/video/"
    video = video_url.split("vimeo.com/")
    video_code = video[1]
  }
  if (video_url.includes("watch?")) {
    video_plattform = "https://www.youtube-nocookie.com/embed/"
    video = video_url.split("?v=")
    video_code = video[1]
  }
  if (video_url.includes("/embed/")) {
    video_plattform = "https://www.youtube-nocookie.com/embed/"
    video = video_url.split("/embed/")
    video_code = video[1]
  }
  if (video_url.includes(".be/")) {
    video_plattform = "https://www.youtube-nocookie.com/embed/"
    video = video_url.split(".be/")
    video_code = video[1]
  }
  // Cookiebot doesn't have a serviceId for youtube, therefore use MARKETING here
  const consentState = useCookiebotConsent(CookiebotServiceId.MARKETING);

  return (
    <div className={`embed embed-${ratio} ${className ? className : ""}`}>
      {consentState != 'accepted' ? (
        <CookiebotPlaceholder
          renderWrapper={false}
          textPositionClassName="text-left"
        />
      ) : (
        <iframe
          className="bg-black embed-item"
          title={"iframe-" + _uid}
          width="1920"
          height="1080"
          src={video_plattform + video_code}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </div>
  )
}

export default Video
