// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   video_url -> text / Video-URL
//   caption -> textarea / Bildbeschreibung (optional)
// end of automatic

import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import VideoFigure from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/VideoFigure"

import ImageReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/ImageReveal"

const VideoBlock = ({ block }) => {
  return (
    <BlockWrapper block={block}>
      <ImageReveal>
        <VideoFigure
          video_url={block.video_url}
          ratio={block.ratio}
          _uid={block._uid}
          caption={block.caption}
        />
      </ImageReveal>
    </BlockWrapper>
  )
}

export default VideoBlock
