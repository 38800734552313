import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Figure from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/Figure"

const DoubleImageBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="true" blockWidth="container">
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="w-full lg:w-1/2 px-grid">
          <Figure
            className="mt-8"
            image={block.image_left}
            alt={block.alt_left}
            title={block.title_left}
            caption={block.caption_left}
            aspectRatio={block.aspect_ratio_left}
          />
        </div>
        <div className="w-full lg:w-1/2 px-grid">
          <Figure
            className="mt-8"
            image={block.image_right}
            alt={block.alt_right}
            title={block.title_right}
            caption={block.caption_right}
            aspectRatio={block.aspect_ratio_right}
          />
        </div>
      </div>
    </BlockWrapper>
  )
}

export default DoubleImageBlock
