// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   headline -> text / Überschrift
//   headline_level -> option
//   image -> asset / Bild
//   caption -> textarea / Bildbeschreibung (optional)
//   block_width -> option
// end of automatic

import React from "react"
import Figure from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/Figure"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const ImageBlock = ({ block }) => {
  return (
    <>
      {block.image?.filename && (
        <BlockWrapper block={block} showHeadline="true" className="">
          <Figure
            className="mt-8"
            image_width={block.block_width}
            image={block.image}
            alt={block.alt}
            title={block.title}
            caption={block.caption}
            aspectRatio={block.aspect_ratio}
          />
        </BlockWrapper>
      )}
    </>
  )
}

export default ImageBlock
