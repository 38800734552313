import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import service from "../../images/mm-service-logo.svg"
import software from "../../images/mm-software-logo.svg"
import data from "../../images/mm-data-logo.svg"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

import ImageReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/ImageReveal"
import HeadlineReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/HeadlineReveal"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"

const SectorTeaserBlock = ({ block }) => {
  return (
    <BlockWrapper block={block}>
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="relative w-full sm:w-1/2 px-grid">
          <ImageReveal>
            <Image image={block.image} aspectRatio="1by1" />
          </ImageReveal>
        </div>
        <div
          className={`relative w-full mt-8 sm:mt-0 sm:w-1/2 px-grid ${
            block.text_position === "left" ? "lg:order-first" : ""
          }`}
        >
          <ImageReveal>
            <Image
              className="block h-20 mb-4"
              image={
                block.sector === "service"
                  ? service
                  : block.sector === "software"
                  ? software
                  : block.sector === "data"
                  ? data
                  : block.sector === "magazin"
                  ? magazin
                  : ""
              }
            />
          </ImageReveal>
          <HeadlineReveal>
            <Headline
              className="w-full mb-4 headline headline-h2"
              //headlineLevel="h2"
              headline={block.headline}
            />
          </HeadlineReveal>
          <div className="lg:ml-16">
            <TextReveal>
              <Richtext text={block.text} />
            </TextReveal>
            <ImageReveal>
              <Link className="mt-4 btn btn-primary" link={block.link}>
                {block.link_text}
              </Link>
            </ImageReveal>
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default SectorTeaserBlock
