// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   table -> table
//   test -> richtext
//   markdown -> markdown
// end of automatic

import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const TableBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="true" blockWidth="content">
      {block.table !== "" && (
        <div className="prose max-w-none mt-8 overflow-x-auto">
          <table>
            <thead>
              <tr>
                {block.table?.thead?.length > 0 &&
                  block.table.thead.map((thead, index) => (
                    <th key={`thead-${index}`}> {thead.value}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {block.table?.tbody?.length > 0 &&
                block.table.tbody.map((row, index) => (
                  <tr key={`tr-${index}`}>
                    {row.body?.length > 0 &&
                      row.body.map((item, index) => (
                        <td key={`td-${index}`}>
                          <Richtext className="prose-sm" text={item.value} />
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <Richtext text={block.markdown} className="mt-8" />
    </BlockWrapper>
  )
}

export default TableBlock
