import React, { useState, useEffect } from "react"
import RatingsAccordeon from "./RatingsAccordeon"

const TabbedRatings = ({ ratings }) => {
  const [tabGroups, setTabGroups] = useState(null)

  // groups ratings by main tarif
  const groupTarifs = (arr) => {
    const res = {}
    arr.forEach(({ tarifname, filter, ...item }) => {
      if (!res[filter] && filter !== tarifname && item.rating !== null) {
        res[filter] = {
          title: filter,
          id: filter,
          items: [],
        }
      }
      res[filter] !== res[tarifname] &&
        res[filter].items.push({ filter, tarifname, ...item })
      if (res[filter]?.title === null) {
        return (
          (res[filter].title = "Hauptrating"), (res[filter].id = "Hauptrating")
        )
      }
    })
    return Object.values(res).sort(
      (rating) => rating.title === "Hauptrating" && -1
    )
  }

  useEffect(() => {
    ratings && ratings.length > 0 && setTabGroups(groupTarifs(ratings))
  }, [ratings])

  return (
    <>
      <TabComp group={tabGroups} />
    </>
  )
}

const TabComp = ({ group }) => {
  const [activeTabState, setActiveTabState] = useState(1)

  return (
    <div className="mt-8 mb-8">
      <nav className="border-b-0 md:border-l md:border-r lg:border-r-0 lg:border-l-0 border-primary">
        {group &&
          group.map((item, index) => (
            <button
              key={index}
              className={`block w-full md:w-1/2 lg:w-auto md:inline-block py-1.5 lg:py-2 px-grid font-bold border-t border-l border-r border-white ${
                index > 0 ? "lg:ml-2" : ""
              } ${
                activeTabState === index + 1
                  ? "text-white bg-primary lg:text-primary lg:bg-transparent border-b-0 border-primary cursor-default"
                  : "text-primary hover:text-secondary border-primary lg:border-transparent lg:border-white lg:hover:border-secondary"
              } ${
                index + 1 === group.length - 1
                  ? "border-b-0 md:border-b lg:border-b-0"
                  : ""
              } ${index + 1 === group.length ? "border-b lg:border-b-0" : ""}`}
              onClick={() => setActiveTabState(index + 1)}
              active="true"
            >
              {item.title}
            </button>
          ))}
      </nav>
      <div className="relative w-full border-t-0 border-b border-primary">
        {group &&
          group.map((item, index) => {
            return (
              <div
                key={index}
                className={`-mx-grid px-grid -my-px ${
                  activeTabState === index + 1 ? "block" : "hidden"
                } `}
              >
                <RatingsAccordeon ratings={item.items} />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default TabbedRatings
