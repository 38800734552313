import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

const ImageHoverTextBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="true">
      <div
        className={`relative overflow-hidden group ${
          block.headline?.length > 0 || block.subheadline?.length > 0
            ? "mt-8"
            : ""
        }`}
      >
        <Image
          className="w-full h-auto"
          image={block.image}
          aspectRatio="16by9"
          forceLoad={true}
        />
        <div className="inset-0 flex flex-col justify-center p-8 duration-300 lg:opacity-0 lg:bg-opacity-50 lg:absolute bg-primary-bg lg:backdrop-filter lg:backdrop-blur-md lg:group-hover:opacity-100">
          <Richtext
            className="prose-lg lg:text-center prose-invert"
            text={block.text}
          />
        </div>
      </div>
    </BlockWrapper>
  )
}

export default ImageHoverTextBlock
