import React from "react"
import Blocks from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/_Blocks"

const BodyBlocks = ({ body, ...rest }) => {
  // console.log("body", body)
  return (
    <div>
      {body &&
        body.map((block) => {
          return React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
            ...rest,
          })
        })}
    </div>
  )
}
export default BodyBlocks
