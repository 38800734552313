import React, { useState, useEffect } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import Orbit from "./infographics/Orbit"
import Satellite from "./infographics/Satellite"
import Constellation from "./infographics/Constellation"

const SwitchedInfoGraphic = ({ block }) => {
  switch (block.layout) {
    case "orbit":
      return (<BlockWrapper block={Object.assign({}, block, {block_width: "screen"})} blockPadding="pb-0"><Orbit block={block} /></BlockWrapper>)
    case "satellite":
      return (<BlockWrapper block={Object.assign({}, block, {block_width: "screen"})} blockPadding="pb-0"><Satellite block={block} /></BlockWrapper>)
    case "constellation":
      return (<BlockWrapper block={Object.assign({}, block, {block_width: "screen"})} blockPadding="pb-0"><Constellation block={block} /></BlockWrapper>)
    default:
      return null
  }
}


const InfoGraphicBlock = ({ block }) => {
  return (
    <SwitchedInfoGraphic block={block} />
  )
}

export default InfoGraphicBlock