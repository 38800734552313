import React, { useState, useEffect } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import RatingsAccordeon from "../blocks/ratings/RatingsAccordeon"
import TabbedRatings from "../blocks/ratings/TabbedRatings"

const RatingsBlock = ({ block, ratings }) => {
  const [hasTabs, setHasTabs] = useState(false)

  if (
    typeof window !== "undefined" &&
    (window.storyblok?.inEditor ||
      window.location.href.indexOf("/storyblok-preview/") !== -1)
  ) {
    return (
      <>
        {typeof window !== "undefined" &&
          (window.storyblok?.inEditor ||
            window.location?.href?.indexOf("/storyblok-preview/") !== -1) && (
            <BlockWrapper block={block} showHeadline={true}>
              <div className="px-0 my-8 lg:px-16 block-w-content">
                <Richtext text={block.text} />
              </div>
              <div className="py-2 my-8">
                <p className="px-4 py-2 text-lg font-bold border-t border-b border-red text-red">
                  In der Storyblok-Vorschau sind die Rating-Daten nicht
                  verfügbar und können deshalb nicht dargestellt werden.
                </p>
              </div>
            </BlockWrapper>
          )}
      </>
    )
  }

  if (ratings) {
    const groupRatings = (ratings) => {
      const filteredRatings = ratings.filter((rating) => {
        if (rating.filter !== null) {
          return rating
        }
        return null
      })
      filteredRatings.length >= 1 && setHasTabs(true)
    }

    useEffect(() => {
      groupRatings(ratings)

      return () => {
        setHasTabs(false)
      }
    }, [ratings])

    return (
      <BlockWrapper block={block} showHeadline={true}>
        <div className="px-0 my-8 lg:px-16 block-w-content">
          <Richtext text={block.text} />
        </div>
        {hasTabs ? (
          <TabbedRatings ratings={ratings} />
        ) : (
          <RatingsAccordeon ratings={ratings} />
        )}
      </BlockWrapper>
    )
  } else {
    return <></>
  }
}

export default RatingsBlock
