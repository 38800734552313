import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import GridWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/GridWrapper"
import SliderWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/SliderWrapper"

import Card from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/Card"
import shuffleArray from "@yumgmbh/gatsby-theme-yum-components/src/utils/shuffleArray"

import useFadeInBottomStaggered from "../effects/useFadeInBottomStaggered"

const CardsBlock = ({ block }) => {
  const { refFadeInBottomStaggeredCollection } = useFadeInBottomStaggered()

  let view = block.view?.length > 0 ? block.view : "grid"
  let showSlides =
    block.show_slides && block.show_slides !== "" ? block.show_slides : 3

  let shuffle = block.shuffle?.length > 0 ? block.shuffle : false

  if (shuffle === true) {
    shuffleArray(block.card_items)
  }

  if (view === "grid") {
    return (
      <BlockWrapper block={block} showHeadline="true">
        <GridWrapper showSlides={showSlides}>
          {block.card_items?.length > 0 &&
            block.card_items.map((item, index) => (
              <div
                ref={refFadeInBottomStaggeredCollection}
                className={`block w-full mt-8 sm:w-1/2 lg:w-1/${showSlides} px-grid`}
                key={index}
              >
                <Card item={item} />
              </div>
            ))}
        </GridWrapper>
      </BlockWrapper>
    )
  } else {
    return (
      <BlockWrapper block={block} showHeadline="true">
        <SliderWrapper showSlides={showSlides}>
          {block.card_items?.length > 0 &&
            block.card_items.map((item, index) => (
              <div
                className={`block w-full mt-8 sm:w-1/2 lg:w-1/${showSlides} px-grid`}
                key={index}
              >
                <Card item={item} />
              </div>
            ))}
        </SliderWrapper>
      </BlockWrapper>
    )
  }
}

export default CardsBlock
