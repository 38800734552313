import React, { useEffect, useRef } from "react"

const WidgetEmbed = ({ widgetHtml, tagName, evtInvokeDelay, ...rest }) => {
  const WrapperTag = tagName || "div"
  const domReadyEventDelay = evtInvokeDelay || 500
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (!widgetHtml) return

    const slotHtml = document.createRange().createContextualFragment(widgetHtml) // Create a 'tiny' document and parse the html string
    wrapperRef.current.innerHTML = "" // Clear the container
    wrapperRef.current.appendChild(slotHtml) // Append the new content

    // invoke DOMCOntentLoaded since some external JS might depend on it
    window.setTimeout(function() {
      window.document.dispatchEvent(
        new Event("DOMContentLoaded", {
          bubbles: true,
          cancelable: true,
        })
      )
    }, domReadyEventDelay)
  }, [widgetHtml, domReadyEventDelay])

  return <WrapperTag {...rest} ref={wrapperRef} />
}

export default WidgetEmbed
