import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const Card = ({ item }) => {
  return (
    <Link
      className={`block h-full text-white transition-all duration-200 ease-in transform bg-${item.style} hover:scale-105 hover:shadow-lg`}
      link={item.link}
    >
      <div className="p-8">
        {item.kicker?.length > 0 && (
          <span
            className={`inline-block px-3 py-1 font-bold uppercase bg-white text-${item.style} lg:text-lg font-display`}
          >
            {item.kicker}
          </span>
        )}
        {item.headline?.length > 0 && (
          <span className="block mt-2 text-2xl font-black hyphens-auto lg:text-3xl font-display">
            {item.headline}
          </span>
        )}
        {item.subheadline?.length > 0 && (
          <span className="block mt-4 font-bold">{item.subheadline}</span>
        )}
      </div>
    </Link>
  )
}

export default Card
