import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import HeadlineReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/HeadlineReveal"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

const LoginOptionsBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} blockPadding="pb-block">
      <div className="flex flex-row flex-wrap -mx-grid">
        {block.login_options.map((option, index) => (
          <div className="w-full mb-12 md:w-1/2 px-grid" key={index}>
            <div className="lg:pl-16">
              {option?.image?.filename?.length > 0 && (
                <Link className="block sm:w-4/6" link={option.link}>
                  <Image className="w-full h-auto " image={option.image} />
                </Link>
              )}
              {option?.link_text?.length > 0 && (
                <div>
                  <Link
                    className="mt-4 md:mt-8 btn btn-primary"
                    link={option.link}
                  >
                    {option.link_text}
                  </Link>
                </div>
              )}
              {option?.alternative_link_text?.length > 0 && (
                <Link
                  className="inline-block mt-4 font-bold underline text-primary hover:text-secondary-hover"
                  link={option.alternative_link}
                >
                  {option.alternative_link_text}
                </Link>
              )}
            </div>
            <div className="hidden mt-12 md:block">
              {option?.headline?.length > 0 && (
                <HeadlineReveal className="headline">
                  <Headline
                    className="relative inline-block w-full py-2 headline-h3"
                    headline={option.headline}
                    headlineLevel="h2"
                    tagName="h2"
                  />
                </HeadlineReveal>
              )}
              {option?.subheadline?.length > 0 && (
                <TextReveal className="mb-4 lg:ml-32 lg:w-auto">
                  <span className="inline-block subheadline-h3">
                    {option.subheadline}
                  </span>
                </TextReveal>
              )}
            </div>
            <div className="hidden lg:pl-16 md:block">
              <Richtext className="mt-8" text={option.info_text} />
              {option?.order_link_text?.length > 0 && (
                <Link
                  className="inline-block mt-8 text-lg font-bold text-primary hover:text-secondary-hover"
                  link={option.order_link}
                >
                  {`---> ${option.order_link_text}`}
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </BlockWrapper>
  )
}

export default LoginOptionsBlock
