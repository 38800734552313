// extracted by mini-css-extract-plugin
export var a = "MMAnalyzerOrderFormBlock-module--a--HaWoz";
export var alertDanger = "MMAnalyzerOrderFormBlock-module--alert-danger--bVyVW";
export var btn = "MMAnalyzerOrderFormBlock-module--btn--ktI2p";
export var btnPrimary = "MMAnalyzerOrderFormBlock-module--btn-primary--u0Lxc";
export var error = "MMAnalyzerOrderFormBlock-module--error--nb8Py";
export var errorMessage = "MMAnalyzerOrderFormBlock-module--errorMessage--bCFej";
export var form = "MMAnalyzerOrderFormBlock-module--form--IeXiG";
export var formContainer = "MMAnalyzerOrderFormBlock-module--formContainer--U3wYy";
export var grey = "MMAnalyzerOrderFormBlock-module--grey--KunGa";
export var h1 = "MMAnalyzerOrderFormBlock-module--h1--UZIHh";
export var h2 = "MMAnalyzerOrderFormBlock-module--h2--f3UfJ";
export var h3 = "MMAnalyzerOrderFormBlock-module--h3--NBIEl";
export var h4 = "MMAnalyzerOrderFormBlock-module--h4--w9Syg";
export var h5 = "MMAnalyzerOrderFormBlock-module--h5--VOiKY";
export var h6 = "MMAnalyzerOrderFormBlock-module--h6--RtcyV";
export var li = "MMAnalyzerOrderFormBlock-module--li--TETco";
export var main = "MMAnalyzerOrderFormBlock-module--main--HeHET";
export var modulPrice = "MMAnalyzerOrderFormBlock-module--modul-price--Js1Dv";
export var modulPriceBlock = "MMAnalyzerOrderFormBlock-module--modul-price-block--u4WIk";
export var modulPriceBlockInner = "MMAnalyzerOrderFormBlock-module--modul-price-block-inner--5wXzm";
export var p = "MMAnalyzerOrderFormBlock-module--p--cO9xn";
export var row = "MMAnalyzerOrderFormBlock-module--row--WgTLO";
export var small = "MMAnalyzerOrderFormBlock-module--small--npCTr";
export var ul = "MMAnalyzerOrderFormBlock-module--ul--xWxyM";