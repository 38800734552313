import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import ButtonGroup from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/ButtonGroup"

const ButtonsBlock = ({ block }) => {
  return (
    <BlockWrapper blockPadding="-mt-4 lg:-mt-8 pt-0 pb-block" block={block}>
      {block.buttons?.length > 0 && (
        <ButtonGroup
          className={block.buttons_centered ? "text-center" : ""}
          buttons={block.buttons}
        />
      )}
    </BlockWrapper>
  )
}

export default ButtonsBlock
