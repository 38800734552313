import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import useFadeInBottomStaggered from "../../@yumgmbh/gatsby-theme-yum-components/components/effects/useFadeInBottomStaggered"

const PricingBlock = ({ block }) => {
  const { refFadeInBottomStaggeredCollection } = useFadeInBottomStaggered()

  return (
    <BlockWrapper block={block} showHeadline="true">
      <div className="flex flex-row flex-wrap -mx-grid">
        {block.pricing_items?.length > 0 &&
          block.pricing_items.map((item, index) => (
            <div
              ref={refFadeInBottomStaggeredCollection}
              className={`relative mt-8 w-full md:w-1/2 ${
                block.pricing_items.length === 2 ? "" : "lg:w-1/3"
              } px-grid group`}
              key={index}
            >
              <Link
                className="block p-4 text-white transition-all duration-200 ease-in transform bg-primary hover:scale-105 hover:shadow-lg hover:bg-secondary"
                link={item.link}
              >
                {item.name?.length > 0 && (
                  <span className="block mb-4 text-2xl font-bold uppercase font-display">
                    {item.name}
                  </span>
                )}
                {item.price?.length > 0 && (
                  <>
                    <span className="block mb-4 font-bold uppercase text-7xl font-display">
                      {item.price}
                    </span>
                    <span className="block mb-6 text-xl font-bold uppercase">
                      {item.accounting_period}
                    </span>
                  </>
                )}
                {item.text?.length > 0 && (
                  <Richtext className="mb-4 prose-invert" text={item.text} />
                )}
                {item.link_text?.length > 0 && (
                  <span className="bg-white border-2 border-white btn text-secondary">
                    {item.link_text}
                  </span>
                )}
              </Link>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default PricingBlock
