import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const useFadeInBottomStaggered = () => {
  const refItems = useRef([])
  //refItems.current = []

  const refFadeInBottomStaggeredCollection = (el) => {
    if (el && !refItems.current.includes(el)) {
      refItems.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(refItems.current, {
      autoAlpha: 0,
      y: 50,
      duration: 0.75,
      stagger: 0.25,
      ease: "power1.out",
      scrollTrigger: {
        scrub: 2,
        trigger: refItems.current[0],
        toggleActions: "play none none none",
        start: "start 100%",
        end: "bottom 90%",
      },
    })
  }, [])
  return { refFadeInBottomStaggeredCollection }
}

export default useFadeInBottomStaggered
