import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import { useCookiebotConsent } from "../../../../hooks/useCookiebotConsent"
import CookiebotPlaceholder from "../../../../components/modules/CookiebotPlaceholder"
import WidgetEmbed from "../technical/WidgetEmbed"

const EmbedBlock = ({ block }) => {
  const consentState = useCookiebotConsent(block.user_consent);

  return (
    <BlockWrapper block={block}>
      {typeof window !== 'undefined' && window.storyblok?.inEditor && (
        <div className="py-2 mb-8 rounded px-grid" style={{ backgroundColor: "#09b3af"}}>
          <p className="font-medium prose text-white">
            <h3 style={{color: "#ffffff"}}>Hinweis:</h3>
            <b>Embed-Block:</b> Eingebettete Inhalte, welche Scripte verwenden, können unter Umständen in der <i>Vorschau</i> nicht korrekt angezeigt werden.
          </p>
        </div>
      )}
      {block.user_consent && consentState !== 'accepted' ? (
        <div className={`embed embed-${block.ratio}`} >
          <CookiebotPlaceholder
            renderWrapper={false}
            textPositionClassName="text-left"
            serviceId={block.user_consent}
          />
        </div>
      ) : (
        <WidgetEmbed
          tagName="div"
          className={`embed embed-${block.ratio}`}
          evtInvokeDelay={500}
          widgetHtml={block.embed_code}
        />
        // <div
        //   dangerouslySetInnerHTML={{ __html: block.embed_code }}
        // ></div>
      )}
    </BlockWrapper>
  )
}

export default EmbedBlock
