// extracted by mini-css-extract-plugin
export var a = "MMOfficeOrderFormBlock-module--a--kKhfO";
export var alertDanger = "MMOfficeOrderFormBlock-module--alert-danger--jyp3T";
export var btn = "MMOfficeOrderFormBlock-module--btn--6FL8H";
export var btnPrimary = "MMOfficeOrderFormBlock-module--btn-primary--TT9k4";
export var error = "MMOfficeOrderFormBlock-module--error--A-QOJ";
export var errorMessage = "MMOfficeOrderFormBlock-module--errorMessage--e32ja";
export var form = "MMOfficeOrderFormBlock-module--form--0-hki";
export var formContainer = "MMOfficeOrderFormBlock-module--formContainer--YCFIf";
export var grey = "MMOfficeOrderFormBlock-module--grey--mRjry";
export var h1 = "MMOfficeOrderFormBlock-module--h1--tlffn";
export var h2 = "MMOfficeOrderFormBlock-module--h2--pppLl";
export var h3 = "MMOfficeOrderFormBlock-module--h3--4tL+w";
export var h4 = "MMOfficeOrderFormBlock-module--h4--PZo74";
export var h5 = "MMOfficeOrderFormBlock-module--h5--IZMW7";
export var h6 = "MMOfficeOrderFormBlock-module--h6--KtwdM";
export var li = "MMOfficeOrderFormBlock-module--li--Ok0He";
export var main = "MMOfficeOrderFormBlock-module--main--RniT6";
export var modulPrice = "MMOfficeOrderFormBlock-module--modul-price--7xvsB";
export var modulPriceBlock = "MMOfficeOrderFormBlock-module--modul-price-block--zvFJM";
export var modulPriceBlockInner = "MMOfficeOrderFormBlock-module--modul-price-block-inner--NhmIk";
export var p = "MMOfficeOrderFormBlock-module--p--3jpk6";
export var row = "MMOfficeOrderFormBlock-module--row--LGLZj";
export var small = "MMOfficeOrderFormBlock-module--small--fIxTV";
export var ul = "MMOfficeOrderFormBlock-module--ul--UbHQe";