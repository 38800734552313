import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import GridWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/GridWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import count3 from "../../images/count-3.svg"
import count2 from "../../images/count-2.svg"
import count1 from "../../images/count-1.svg"

import useFadeInBottomStaggered from "../../@yumgmbh/gatsby-theme-yum-components/components/effects/useFadeInBottomStaggered"

const ThreeTwoOneBlock = ({ block }) => {
  const { refFadeInBottomStaggeredCollection } = useFadeInBottomStaggered()

  return (
    <BlockWrapper block={block} showHeadline="true">
      <GridWrapper>
        <div className="w-full mt-8 md:w-1/3 px-grid">
          <div className="flex flex-row md:flex-col -mx-grid md:mx-0">
            <div ref={refFadeInBottomStaggeredCollection}>
              <div className="relative w-1/3 md:w-full px-grid md:px-0">
                <Image className="block mx-auto mb-8 md:w-2/3" image={count3} />
              </div>
              <Richtext
                className="w-2/3 md:w-full px-grid md:px-0"
                text={block.text_left}
              />
            </div>
          </div>
        </div>

        <div className="w-full mt-8 md:w-1/3 px-grid">
          <div className="flex flex-row md:flex-col -mx-grid md:mx-0">
            <div ref={refFadeInBottomStaggeredCollection}>
              <div className="relative w-1/3 md:w-full px-grid md:px-0">
                <span className="absolute hidden font-bold transform -translate-y-1/2 md:inline-block -translate-x-7 text-7xl top-1/2">
                  :
                </span>
                <Image className="block mx-auto mb-8 md:w-2/3" image={count2} />
              </div>
              <Richtext
                className="w-2/3 md:w-full px-grid md:px-0"
                text={block.text_middle}
              />
            </div>
          </div>
        </div>

        <div className="w-full mt-8 md:w-1/3 px-grid">
          <div className="flex flex-row md:flex-col -mx-grid md:mx-0">
            <div ref={refFadeInBottomStaggeredCollection}>
              <div className="relative w-1/3 md:w-full px-grid md:px-0">
                <span className="absolute hidden font-bold transform -translate-y-1/2 md:inline-block -translate-x-7 text-7xl top-1/2">
                  :
                </span>
                <Image className="block mx-auto mb-8 md:w-2/3" image={count1} />
              </div>
              <Richtext
                className="w-2/3 md:w-full px-grid md:px-0"
                text={block.text_right}
              />
            </div>
          </div>
        </div>
      </GridWrapper>
    </BlockWrapper>
  )
}

export default ThreeTwoOneBlock
