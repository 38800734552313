import React from 'react';
import clsx from 'clsx';
import { Field } from 'formik';
import * as styles from './CheckboxField.module.css';

export default function CheckboxField({ label, id, name, values, required, disabled, touched, submitCount, errors, type, className, onChange }) {
  type = type ?? 'text';
  id = id ?? name;
  const value = values[name] ?? '';
  disabled = disabled ?? false,
  touched = touched[name] ?? false;
  submitCount = submitCount ?? 0;
  const error = errors[name] ?? undefined;
  return (
    <div className={clsx(styles.formCheck, className)}>
      <Field
        type="checkbox"
        id={id}
        name={name}
        disabled={disabled}
        onChange={onChange}
        className={clsx(styles.formCheckInput)}
      />
      <label className={clsx(styles.label, disabled && styles.disabled, submitCount > 0 && touched && (error ? styles.errorMessage : styles.validMessage))} htmlFor={id}>{label}{required && (<abbr title="Pflichtfeld">*</abbr>)}</label>
    </div>
  )
}
