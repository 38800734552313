// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   subheadline -> text
//   stat_items -> bloks / Kennzahlen Items
// end of automatic

import React, { useRef, useEffect } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const useCountUp = () => {
  const refCountUpTrigger = useRef(null)
  const refCountUp = useRef([])
  refCountUp.current = []

  const refCountUpCollection = (el) => {
    if (el && !refCountUp.current.includes(el)) {
      refCountUp.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(refCountUp.current, {
      duration: 1,
      stagger: 0.25,
      ease: "ease.out",
      innerText: 0,
      snap: { innerText: 1 },
      scrollTrigger: {
        trigger: refCountUpTrigger.current,
        toggleActions: "play none none reverse",
        start: "start 75%",
        end: "bottom 25%",
      },
    })
  }, [])
  return [refCountUpTrigger, refCountUpCollection]
}

const StatsBlock = ({ block }) => {
  const [refCountUpTrigger, refCountUpCollection] = useCountUp()
  return (
    <BlockWrapper
      block={block}
      showHeadline="true"
      centerHeadline="true"
      className="fade-in-up animation-delay-1000"
    >
      <div ref={refCountUpTrigger} className="flex flex-row flex-wrap -mx-grid">
        {block.stat_items?.length > 0 &&
          block.stat_items.map((item, index) => (
            <div
              className={`w-full ${
                block.headline?.length > 0 || block.subheadline?.length > 0
                  ? "mt-8"
                  : ""
              } md:w-1/2 lg:w-1/3 px-grid`}
              key={index}
            >
              <div className="">
                <span
                  ref={refCountUpCollection}
                  className="block text-4xl font-bold leading-none sm:text-5xl lg:text-7xl text-primary"
                >
                  {item.number}
                </span>
                {item.unit && (
                  <span className="block mb-2 text-xl font-semibold md:mb-4 lg:text-2xl text-primary">
                    {item.unit}
                  </span>
                )}
                <span className="block text-base font-bold lg:text-xl">
                  {item.title}
                </span>
                {item.text && <Richtext text={item.text} />}
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default StatsBlock
