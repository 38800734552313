import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import ImageReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/ImageReveal"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"

import Dot from "../../../../images/dot.svg"

const TeamCard = ({ item }) => {
  return (
    <div className="flex flex-col sm:flex-row -mx-grid">
      {item.image?.filename?.length > 0 && (
        <div className="w-full sm:w-1/3 lg:w-1/2 px-grid">
          <ImageReveal>
            <Image
              image={item.image}
              className="w-full h-auto"
              aspectRatio="1by1"
            />
          </ImageReveal>
        </div>
      )}
      <div className="flex flex-col justify-center w-full mt-4 sm:mt-0 sm:w-2/3 lg:w-1/2 px-grid">
        <TextReveal>
          {(item.firstname || item.name) && (
            <div className="uppercase font-display">
              {item.firstname && (
                <span className="block text-xl font-bold lg:text-4xl ">
                  {item.firstname}
                </span>
              )}
              {item.name && (
                <span className="block text-xl font-bold lg:text-4xl ">
                  {item.name}
                </span>
              )}
              <span
                className="block w-full h-2 my-2 sm:w-64 lg:w-96 lg:my-4"
                style={{
                  backgroundImage: "url(" + Dot + ")",
                }}
              />
            </div>
          )}
          {item.description && (
            <Richtext
              className="block mb-4 lg:text-lg text-primary"
              text={item.description}
            />
          )}
          {item.tel && (
            <a
              className="block mb-2 lg:text-lg text-secondary hover:text-primary-hover"
              href={"tel:" + item.tel}
            >
              {item.tel}
            </a>
          )}
          {item.email && (
            <a
              className="block lg:text-lg text-secondary hover:text-primary-hover"
              href={"mailto:" + item.email}
            >
              {item.email}
            </a>
          )}
          <Richtext text={item.text} />
        </TextReveal>
      </div>
    </div>
  )
}

export default TeamCard
