import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import GridWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/GridWrapper"
import useFadeInBottomStaggered from "../../@yumgmbh/gatsby-theme-yum-components/components/effects/useFadeInBottomStaggered"
import Card from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/Card"

const RelatedNewsBlock = ({ block, name, relatedNews }) => {
  const { refFadeInBottomStaggeredCollection } = useFadeInBottomStaggered()

  const news = relatedNews?.filter((n) => n.name !== name) || []

  news.length > 3 && news.pop()

  return (
    <BlockWrapper
      block={{ ...block, headline: "DAS KÖNNTE DICH INTERESSIEREN" }}
      showHeadline="true"
    >
      {typeof window !== "undefined" && window.storyblok?.inEditor && (
        <div
          className="py-2 mb-8 rounded px-grid"
          style={{ backgroundColor: "#09b3af" }}
        >
          <p className="font-medium prose text-white">
            <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
            <b>Related-News-Block:</b> Komponenten, die abhängige Datenabrufe
            erfordern, können unter Umständen in der <i>Vorschau</i> nicht
            korrekt angezeigt werden.
          </p>
        </div>
      )}
      <GridWrapper>
        {news?.length > 0 &&
          news?.map((item, index) => (
            <div
              ref={refFadeInBottomStaggeredCollection}
              className="block w-full mt-8 sm:w-1/2 lg:w-1/3 px-grid"
              key={index}
            >
              <Card
                item={{
                  kicker: item.field_kicker_string,
                  headline: item.field_headline_string
                    ? item.field_headline_string
                    : item.name,
                  subheadline: item.field_subheadline_string,
                  link: `/${item.full_slug}`,
                  style: item.field_card_style_string,
                }}
              />
            </div>
          ))}
      </GridWrapper>
    </BlockWrapper>
  )
}

export default RelatedNewsBlock
