import React from "react"

const CookiebotPlaceholder = ({ renderWrapper = false, textPositionClassName = "text-center", serviceId = false}) => {
  // Cookiebot doesn't provide description texts for services therefore let's hardcode it here
  let title = 'Ihre Zustimmung ist erforderlich';
  let serviceName = null;
  let text = 'Dieser Inhalt kann aufgrund Ihrer Datenschutz-Einstellungen nicht geladen werden';
  switch (serviceId) {
    case 'youtubevideo':
      title = 'Ihre Zustimmung ist erforderlich';
      serviceName = 'Youtube Videos';
      text = 'Youtube ist ein Video-Player-Dienst.'
      break;
  }

  return (
    <>
      { renderWrapper ? (
        <section className="block-w-container py-10">
          <div className="text-left">
            <div className="prose">
              <h3>{title}</h3>
              <p>{serviceName !== null && (<b>{serviceName}: </b>)}{text}</p>
              <button className="btn btn-primary" onClick={e => {
                e.preventDefault();
                window.Cookiebot?.show();
              }}>Verwendung erlauben</button>
            </div>
          </div>
        </section>
      ) : (
        <div className={textPositionClassName}>
          <div className="prose">
            <h3>{title}</h3>
            <p>{serviceName !== null && (<b>{serviceName}: </b>)}{text}</p>
            <button className="btn btn-primary" onClick={e => {
              e.preventDefault();
              window.Cookiebot?.show();
            }}>Verwendung erlauben</button>
          </div>
        </div>
      )}
    </>
  )
}

export default CookiebotPlaceholder
