import React, { useState } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Blocks from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/_Blocks"

const TabFolderBlock = ({ block }) => {
  const [activeTabState, setActiveTabState] = useState(1)
  return (
    <BlockWrapper block={block} showHeadline="true">
      <nav
        className={` md:border-l md:border-r lg:border-r-0 lg:border-l-0 border-primary ${
          block.headline?.length > 0 || block.subheadline?.length > 0
            ? "mt-8"
            : ""
        }`}
      >
        {block.folder_items &&
          block.folder_items.length > 0 &&
          block.folder_items.map((item, index) => (
            <button
              key={index}
              className={`block w-full md:w-1/2 lg:w-auto md:inline-block py-1.5 lg:py-2 px-grid font-bold border-t border-l border-r border-white ${
                index > 0 ? "lg:ml-2" : ""
              } ${
                activeTabState === index + 1
                  ? "text-white bg-primary lg:text-primary lg:bg-transparent border-b-0 border-primary cursor-default"
                  : "text-primary hover:text-secondary border-primary lg:border-transparent lg:border-white lg:hover:border-secondary"
              } ${
                index + 1 === block.folder_items.length - 1
                  ? "border-b-0 md:border-b lg:border-b-0"
                  : ""
              } ${
                index + 1 === block.folder_items.length
                  ? "border-b lg:border-b-0"
                  : ""
              }`}
              onClick={() => setActiveTabState(index + 1)}
              active="true"
            >
              {item.title}
            </button>
          ))}
      </nav>
      <div className="relative w-full border-t-0 border-b border-l border-r lg:border-l-0 lg:border-r-0 lg:border-t border-primary">
        {block.folder_items &&
          block.folder_items.length > 0 &&
          block.folder_items.map((item, index) => (
            <div
              key={index}
              className={`-mx-grid ${
                activeTabState === index + 1 ? "block" : "hidden"
              } `}
            >
              {item.content &&
                item.content.map((content, index) =>
                  React.createElement(Blocks(content.component), {
                    key: index,
                    block: content,
                    type: content.component,
                    className: "-mx-grid",
                  })
                )}
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default TabFolderBlock
